import React from 'react';
import "../Home/HomeContact.css";
import TextArea from 'antd/es/input/TextArea';
import { Button, Form, Input } from 'antd';
const HomeContact = () => {
    return (
        <>
            <section className='contact-page-section-2'>
                <div className='contact-page-section-2-form-con'>
                    <div className='contact-page-section-2-form'>
                        <div className='contact-page-section-2-heading'>
                            <h2>Enquiry Form Tagline</h2>
                            <h3>Write us a message.</h3>
                        </div>
                        <div className='contact-page-section-2-main-form'>
                            <Form>
                                <Form.Item>
                                    <Input placeholder='Full Name' />
                                </Form.Item>
                                <Form.Item>
                                    <Input placeholder='Email' />
                                </Form.Item>
                                <Form.Item>
                                    <Input placeholder='Phone Number' />
                                </Form.Item>
                                <Form.Item>
                                    <TextArea placeholder='Message' rows={6} />
                                </Form.Item>
                                <Form.Item>
                                    <div className='contact-page-sumbit-btn'>
                                        <Button>
                                            Send
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                            <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeContact