import React, { useEffect, useState } from 'react';
import "../Css/Home.css";
import bannerImage from "../Images/Home/banner Image 01.png";
import About from '../Component/Home/About';
import HomeProduct from '../Component/Home/HomeProduct';
import Testimonial from '../Component/HomeTestimonial/Testimonial';
import HomeContact from "../Component/Home/HomeContact";
import HomeFaq from '../Component/Home/HomeFaq.jsx';
import Process from '../Component/Home/Process.jsx';
import HomeCategory from '../Component/Home/HomeCategory.jsx';
import HomeNewArrival from '../Component/Home/HomeNewArrival.jsx';
import axios from 'axios';

const Home = () => {


    return (
        <>
            <section className='Home_Section-01'>
                <div className='Home-Section-1-Banner-con'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='home-section-banner-details-con'>
                                <div className='home-section-banner-details'>
                                    <h2>Invest Your Money With <span>Higher Return</span></h2>
                                    <p>Anyone can invest money to different currency to increase their earnings by the help of Bitrader through online.</p>
                                    <div className='home-section-banner-btn-con'>
                                        <button>
                                            About More
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='Home-Section-banner-Image-con'>
                                <div className='Home-section-banner-image'>
                                    <img src={bannerImage} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeProduct />
            <About />
            <HomeCategory />
            <Process />
            <HomeNewArrival />
            <HomeContact />
            <HomeFaq />
            <Testimonial />
        </>
    )
}

export default Home