import React, { useEffect, useRef, useState } from 'react';
import "../HomeTestimonial/Testimonial.css";
import { FaStar } from "react-icons/fa";
import TestimonialData from './TestimonialData';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';


const Testimonial = () => {

    const [testimonialData, setTestimonialData] = useState([])
    console.log("testimonialData",testimonialData)
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get('https://skdm.in/server/bhavinjavari/testimonals.php?action=testimonialFetch');
                setTestimonialData(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
    }, []);

    return (
        <>
            <section className="testimonial-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="left-content">
                                <div className="sec-title">
                                    <h2 className='Head-Style-Service'>
                                        Words From
                                        <br /> Our Happy Clients
                                    </h2>
                                </div>
                                <div className="text-holder">
                                    <p>
                                        How all this mistaken idea of denouncing pleasure and praising
                                        pain was bornd and I will give you a completed account of the
                                        system, and expound.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="testimonial-carousel owl-carousel owl-theme owl-loaded">
                                <div className="owl-stage-outer">
                                    <div
                                        className="owl-stage"
                                        style={{
                                            transition: "all 0.7s ease 0s",
                                        }}
                                    >
                                        <div
                                            className="owl-item cloned"
                                        >
                                            <Swiper
                                                spaceBetween={30}
                                                centeredSlides={true}
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Autoplay, Pagination]}
                                                className="mySwiper"
                                            >
                                                {
                                                    testimonialData.map((elem) => {
                                                         return (
                                                            <>
                                                                <SwiperSlide>
                                                                    <div className="single-item">
                                                                        <div className="text-holder">
                                                                            <div className="quote-icon">
                                                                                <img src={elem.TestimonialImage} alt={elem.TestimonialName} />
                                                                            </div>
                                                                            <h3>{elem.test_name}</h3>
                                                                            <p>
                                                                                {elem.test_content}
                                                                            </p>
                                                                            <div className="review-box">
                                                                                <FaStar />
                                                                                <FaStar />
                                                                                <FaStar />
                                                                                <FaStar />
                                                                                <FaStar />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            </>
                                                        )
                                                    })
                                                }
                                                {/* </div> */}
                                                {/* </div> */}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Testimonial