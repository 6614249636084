import React, { useEffect, useState } from 'react';
import "../../Css/ProfileEdit.css";
import axios from 'axios';
import { Button, Form, Input, Modal, message } from 'antd';

const ProfileEdit = () => {
    const [form] = Form.useForm();
    const tokenid = sessionStorage.getItem("access_token");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profileData, setProfileData] = useState({
        username: '',
        email: '',
        phone: ''
    });

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        const storedProfileData = JSON.parse(sessionStorage.getItem('profile'));
        console.log("Stored profile data:", storedProfileData); // Add this line to check stored profile data
        try {
            const response = await axios.get(`https://skdm.in/server/bhavinjavari/user.php?&action=fetchUserByToken&token=${tokenid}`);
            if (response.data) {
                console.log("response",response.data.profile)
                form.setFieldsValue({
                    user_name: response.data.profile.user_name,
                    user_email: response.data.profile.user_email,
                    user_phone: response.data.profile.user_phone
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        form.validateFields().then(values => {
            console.log(values)
            const formDataToSend = new FormData();
            formDataToSend.append('User_Name', values.user_name);
            formDataToSend.append('User_Email', values.user_email);
            formDataToSend.append('User_Phone', values.user_phone);
            formDataToSend.append('action', "update_profile");
            formDataToSend.append('token', tokenid);

            console.log(formDataToSend)

            axios.post('https://skdm.in/server/bhavinjavari/user.php', formDataToSend)
                .then(response => {
                    console.log('Data Updated successfully:', response.data);
                    message.success(response.data.status)
                    sessionStorage.setItem('profile', JSON.stringify(values));
                    setProfileData(values);
                    setIsModalOpen(false);
                })
                .catch(error => {
                    console.error('Error updating data:', error);
                });
        });
    };

    return (
        <section className='ProfileEdit'>
            <div className='ProfileEdit-con'>
                <div className='ProfileEdit-con-heading'>
                    <h2>Edit Profile Details</h2>
                </div>
                <div className='ProfileEdit-con-inputs'>
                    <Form layout="vertical" initialValues={profileData} form={form}>
                        <Form.Item name="user_name" label="First Name">
                            <Input type="text" placeholder="Enter Name" disabled />
                        </Form.Item>
                        <Form.Item name="user_email" label="Email">
                            <Input type="text" placeholder="Enter Email" disabled />
                        </Form.Item>
                        <Form.Item name="user_phone" label="Mobile Number">
                            <Input type="number" placeholder="Enter Contact" disabled />
                        </Form.Item>
                    </Form>
                </div>
                <div className='ProfileEdit-btn'>
                    <button onClick={showModal}>
                        Update Profile
                    </button>
                </div>
            </div>

            <Modal visible={isModalOpen} onCancel={handleCancel} onOk={handleOk}>
                <Form form={form} layout="vertical">
                    <Form.Item name="user_name" label="First Name">
                        <Input type="text" placeholder="Enter Name" />
                    </Form.Item>
                    <Form.Item name="user_email" label="Email">
                        <Input type="text" placeholder="Enter Email" />
                    </Form.Item>
                    <Form.Item name="user_phone" label="Mobile Number">
                        <Input type="number" placeholder="Enter Contact" />
                    </Form.Item>
                </Form>
            </Modal>
        </section>
    );
};

export default ProfileEdit;
