import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import "../Home/HomeFaq.css";
import axios from 'axios';
const HomeFaq = () => {

    const [faqsData, setFaqsData] = useState([])
    console.log("faqsData", faqsData)
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get('https://skdm.in/server/bhavinjavari/blog.php?action=blogFetch');
                setFaqsData(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
    }, []);

    return (
        <>
            <section className='home-faq-section'>
                <div className="container">
                    <h2 className='home-faq-head'>FAQs</h2>
                    <Accordion defaultActiveKey="0">
                        {
                            faqsData.map((elem, index) => {
                                const { blog_id, content, crated_name } = elem
                                return (
                                    <>
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{crated_name}</Accordion.Header>
                                            <Accordion.Body>
                                                {content}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                )
                            })
                        }
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default HomeFaq;