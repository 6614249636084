import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { setProfile, storeToken } from "../../services/localStorageServices";
import { FaRegCircleUser } from "react-icons/fa6";
import { Breadcrumb, Button, Form, Input, message } from "antd";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import Logo from "../../Images/Logo.png";
import OTPInput from 'react-otp-input';
import "../../Css/account.css";

export default function SignUp() {
  const { id } = useParams(); // Retrieve email from URL parameters
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP send status
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userEmail,setUserEmail] = useState("")

  useEffect(() => {
    // Handle any initial setup or effects
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    
    try {
      const formData = new FormData();
      formData.append("action", "register");
      formData.append("user_name", values.name);
      formData.append("user_email", values.email);
      formData.append("user_phone", values.phone);
      setUserEmail(values.email)

      const response = await axios.post(
        "https://skdm.in/server/bhavinjavari/user.php", formData
      );

      if (response.status >= 200 && response.status < 300) {
        message.success("OTP sent successfully");
        setOtpSent(true); // Set OTP sent status to true
      } else {
        console.error("Signup Error - Unsuccessful response", response);
        message.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Signup Error", error);
      message.error("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    setLoading(true);
console.log("otp",otp)
    try {
      const formData = new FormData();
      formData.append("action", "verify_signup");
      formData.append("otp", otp);
      formData.append('user_email', userEmail);
      const response = await axios.post(
        "https://skdm.in/server/bhavinjavari/user.php", formData
      );

      if (response.status >= 200 && response.status < 300) {
        message.success("OTP verified successfully");
        console.log("response",response)
        dispatch(setUserToken({ token: response.data.token }));
        storeToken(response.data.token);
        setProfile(response.data.profile);
        navigate("/");
      } else {
        console.error("OTP Verification Error", response);
        message.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error("OTP Verification Error", error);
      message.error("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="Register-main-con">
        <div className="container">
          <div className="Register-con">
            <div className="row">
              <div className="col-md-6 d-flex p-0 align-items-center justify-content-end">
                <div className="account-card signUp">
                  <div className="title">
                    <Breadcrumb
                      items={[
                        {
                          title: <Link to="/">Home</Link>,
                        },
                        {
                          title: "Sign Up",
                        },
                      ]}
                    />
                  </div>
                  <div className="account-form">
                    <Form
                      name="normal_login"
                      className="login-form"
                      layout="vertical"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinish}
                    >
                      <div className="row">
                        <div className="col-12">
                          <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                            ]}
                          >
                            <Input
                              prefix={<FaRegCircleUser className="site-form-item-icon" />}
                              placeholder="Full Name"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                              {
                                type: "email",
                                message: "Please enter a valid email!",
                              },
                            ]}
                          >
                            <Input
                              prefix={<MdOutlineEmail className="site-form-item-icon" />}
                              placeholder="Email"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-md-12">
                          <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your phone no.!",
                              },
                              {
                                min: 10,
                                max: 10,
                                message: "Please enter a valid Phone!",
                              },
                            ]}
                          >
                            <Input
                              prefix={<BsTelephone className="site-form-item-icon" />}
                              placeholder="Phone No."
                            />
                          </Form.Item>
                        </div>
                        {otpSent && (
                          <div className="col-12">
                            <div className="SignUp-section-otp-main-con">
                              <div className='Forgot-mail-otp-con-details-Info-Input'>
                                <OTPInput
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={6}
                                  renderSeparator={<span>-</span>}
                                  renderInput={(props) => <input {...props} />}
                                />
                              </div>
                              <div className='Code-expires-con'>
                                <h4>Code Expires in: <span>03:12</span></h4>
                              </div>
                            </div>
                          </div>
                        )}
                        <Form.Item>
                          {otpSent ? (
                            <Button
                              htmlType="button"
                              className="login-form-button"
                              loading={loading}
                              onClick={verifyOtp}
                            >
                              {loading ? "Verifying OTP..." : "Verify OTP"}
                            </Button>
                          ) : (
                            <Button
                              htmlType="submit"
                              className="login-form-button"
                              loading={loading}
                            >
                              {loading ? "Sending OTP..." : "Send OTP"}
                            </Button>
                          )}
                          <br></br>
                          Or <Link to="/account/sign-in">login now!</Link>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex p-0 align-items-center">
                <div className="Login-images-main-con">
                  <div className="Login-image-logo-con">
                    <img src={Logo} alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
