import React, { useEffect, useState } from 'react';
import "../Css/NewArrival.css";
import StockData from '../Component/StockData';
import axios from 'axios';

const NewArrival = () => {

    const [newStocks, setNewStocks] = useState([])
    console.log("newStocks", newStocks)
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get('https://skdm.in/server/bhavinjavari/stock.php?action=stockFetch');
                setNewStocks(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
    }, []);


    return (
        <>
            <section className='new-arrival-section-1'>
                <div className='container'>
                    <div className='new-arrival-section-main-con'>
                        <div className='home-section-3-heading-con'>
                            <h2><span>New</span> Arrival</h2>
                            <p>"Discover the latest trends with our fresh arrivals! Dive into new and exclusive stocks, offering the best picks just for you. Don't miss out—explore now and stay ahead!"</p>
                        </div>

                        <div className='new-arrival-section-details-main-con'>
                            <div className='row'>
                                {
                                    newStocks.map((elem) => {

                                    const { base_price, image, name, price, stock_type } = elem

                                        return (
                                            <>
                                                <div className='col-md-4'>
                                                    <div className='stock-card-main-con'>
                                                        <div className='stock-card-image-con'>
                                                            <img src={image} alt='nse' />
                                                        </div>
                                                        <div className='stock-card-details-con'>
                                                            <h3>{name}</h3>
                                                            <h4>₹{price}</h4>
                                                            <div className='stock-card-details-info-con'>
                                                                <h5>( {elem.profit} )</h5>
                                                                <h5>( {elem.profitpercentage}% )</h5>
                                                                <h6>{elem.some}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewArrival