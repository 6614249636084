import React from 'react';
import "../Home/About.css";
import AboutImage from "../../Images/Home/about_img02.png";

const About = () => {
    return (
        <>
            <section className='Home-Section-2'>
                <div className='container'>
                    <div className='home-section-2-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='home-section-2-Image-con'>
                                    <div className='home-section-2-image'>
                                        <img src={AboutImage} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='home-section-2-About-Details-con'>
                                    <div className='home-section-2about-details'>
                                        <h2>Meet Our <span>Company Unless</span> Miss The Opportunity</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi neque error vitae esse voluptatibus sunt facilis odio voluptates iste officiis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi neque error vitae esse voluptatibus sunt facilis odio voluptates iste officiis!</p>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi neque error vitae esse voluptatibus sunt facilis odio voluptates iste officiis!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About