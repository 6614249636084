import React from "react";
import "../LogOut/ProfileLogout.css";
import Image from "../../Images/logout.png";
import { useNavigate } from "react-router-dom";
const ProfileLogout = () => {
    
    const navigate = useNavigate()

    const logoutHandle = () => {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('profile');
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
    };

    return (
        <>
            <section className="profile-log-out-section">
                <div className="profile-log-out-details">
                    <div className="LogOut-Image-main-con">
                        <div className="Logout-image">
                            <img src={Image} alt="Image" />
                        </div>
                    </div>
                    <div className="profile-logout-text-details">
                        <p>
                            oh no! You'r leaving...
                        </p>
                        <p>
                            Are you sure?
                        </p>
                    </div>
                    <div className="profile-logout-btn">
                        <button  onClick={logoutHandle}>
                            Yes, Log Me Out
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ProfileLogout;
